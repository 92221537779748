import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as projects from '../projects.json';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {
  public currentProject = null;

  constructor(private route: ActivatedRoute) { }
  
  ngOnInit() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.route.queryParams.subscribe(param => {
      this.currentProject = this.getCurrentProject(param.projectId)[0];
    });
  }

  private getCurrentProject(projectId) {
    return projects['default'].filter((data) => {
      return data.id === projectId;
    });
  }

  public getDelay(index) {
    return { 'animation-delay': index / 5 + 's' };
  }

  public isNotPDF(file: string) {
    if (file.split('.')[1] !== 'pdf') {
      return true;
    } else {
      return false;
    }
  }

  public getSrc(file) {
    return '../assets/imgs/projects/' + this.currentProject.projectId + '/' + file + '';
  }
}
