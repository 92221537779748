<div class="responsiveBig" style="overflow:auto;">
  <div class="menuContainer">
    <div class="menuIcon"></div>
    <table class="menuTable">
      <td>
        <tr>&#x25aa; Planung</tr>
        <tr>&#x25aa; Beratung</tr>
      </td>
      <td>
        <tr>&#x25aa; Bauleitung</tr>
        <tr>&#x25aa; Umbau</tr>
      </td>
      <td>
        <tr>&#x25aa; SiGeKo</tr>
      </td>
    </table>
    <div class="menuIconContainer">
      <div class="menuItem" (click)="goHome()"><strong>Home</strong></div>
      <div class="menuItem" (click)="anchorLink('projects')"><strong>Projekte</strong></div>
      <div class="menuItem" (click)="goToAboutMe()"><strong>Über mich</strong></div>
      <div class="menuItem" (click)="anchorLink('footer')"><strong>Kontakt</strong></div>
      <div class="menuItem" (click)="goToImprint()"><strong>Impressum & Datenschutz</strong></div>
    </div>
  </div>
  <div class="subHeader">Hier trifft Architektur und Bautechnik aufeinander.</div>
</div>

<div class="responsiveSmall" style="width:100%; text-align:center">
  <img src="../assets/imgs/DmIcon.PNG" style="width:100%; margin-bottom:1em">
  <div style="width:100%; text-align:center">
    <div class="menuItem" (click)="goHome()"><strong>Home</strong></div>
    <div class="menuItem" (click)="anchorLink('projects')"><strong>Projekte</strong></div>
    <div class="menuItem" (click)="goToAboutMe()"><strong>Über mich</strong></div>
    <div class="menuItem" (click)="anchorLink('footer')"><strong>Kontakt</strong></div>
    <div class="menuItem" (click)="goToImprint()"><strong>Impressum & Datenschutz</strong></div>
  </div>
</div>

<div class="content">
  <router-outlet id="projects"></router-outlet>
</div>
<div [ngStyle]="showScrollUp()" (click)="scrollTop()" class="scrollUpBtn">
  <span class="material-icons iconAlignFix">keyboard_arrow_up</span>
</div>
<div id="footer" class="footer">
  <table class="responsiveBig footerTable">
    <tr>
      <td>
        <span class="material-icons iconAlignFix">person</span> Daniel Martin
      </td>
      <td>
        <span class="material-icons iconAlignFix">local_phone</span><a href="tel:+4916097719402"> +49 160 97719402</a>
      </td>
      <td>
        <span class="material-icons iconAlignFix">place</span> Schwabmünchener Str. 39, 86807 Buchloe
      </td>
      <td>
        <span class="material-icons iconAlignFix">email</span><a href="mailto:info@dmartin-bauplanung.de"> info@dmartin-bauplanung.de</a>
      </td>
    </tr>
  </table>
  <table class="responsiveSmall footerTable" style="height:100%; line-height:2em">
    <tr>
      <td>
        <span class="material-icons iconAlignFix">person</span> Daniel Martin
      </td>
    </tr>
    <tr>
      <td>
        <span class="material-icons iconAlignFix">local_phone</span><a href="tel:+4916097719402"> +49 160 97719402</a>
      </td>
    </tr>
    <tr>
      <td>
        <span class="material-icons iconAlignFix">place</span> Schwabmünchener Str. 39, 86807 Buchloe
      </td>
    </tr>
    <tr>
      <td>
        <span class="material-icons iconAlignFix">email</span><a href="mailto:info@dmartin-bauplanung.de"> info@dmartin-bauplanung.de</a>
      </td>
    </tr>
  </table>
</div>