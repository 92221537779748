<div class="container">
  <div style="margin-bottom: 2em">
    <h2>Leistungen</h2>
  </div>
  <div class="row">
    <div class="serviceCard">
      <h3>Planung</h3>
      <p style="margin:0">Von der Idee zum Entwurf, vom Entwurf zum <strong>Bauantrag</strong>.</p>
      <br>
      <p style="margin:0">Vom Detail in die <strong>Werkplanung.</strong></p>
    </div>
    <div class="serviceCard">
      <h3>Beratung</h3>
      <p style="margin:0">Gemeinsamer Entwurf des Objekts nach Ihren Wünschen in modernsten Standard im Hochbau.</p>
      <br>
      <p style="margin:0">Baubetreuung für die Bauherren von Beginn bis zur Übergabe der Schlüssel.</p>
    </div>
    <div class="serviceCard">
      <h3>Bauleitung</h3>
      <p style="margin:0">Koordination der einzelnen Firmen und Gewerke.</p>
    </div>
    <div class="serviceCard">
      <h3>Umbau</h3>
      <p style="margin:0">Planung Ihres bestehenden Objekts nach neuestem energetischen Standard.</p>
    </div>
    <div class="serviceCard">
      <h3>SiGeKo</h3>
      <p style="margin:0">Sicherheits- und Gesundheitsschutzkoordinator (SiGeKo) für Sicherheit und Gesundheitsschutz auf
        Baustellen.</p>
    </div>
    <div class="serviceCard">
      <h3>Energieberatung und Planung</h3>
      <p style="margin:0">mh² Energie- und Bauplanung</p>
      <br>
      <p style="margin:0"><strong>Telefon:</strong> +49 160 97719402</p>
    </div>
  </div>
</div>