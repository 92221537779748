<div class="container">
  <div class="row" style="display: flex; justify-content: space-between;">
    <div (click)="goToServices()" class="cardContainer">
      <div class="card fade-in">
        <img src="../assets/imgs/DSC_1692-01.jpg" alt="...">
        <div class="card-body">
          <h4 class="card-text">Leistungen</h4>
        </div>
      </div>
    </div>
    <ng-container *ngFor="let element of projectList; let i = index">
      <div (click)="goToProject(element.id)" class="cardContainer">
        <div class="card fade-in">
          <img src="../assets/imgs/projects/{{element.id}}/{{element.mainPic}}" alt="...">
          <div class="card-body">
            <ng-container *ngIf="element.place">
              <h4 class="card-text">{{element.projectName}} in {{element.place}}</h4>
            </ng-container>
            <ng-container *ngIf="!element.place">
              <h4 class="card-text">{{element.projectName}}</h4>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>