import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import * as projects from '../projects.json';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public projectList = [];

  constructor(private router: Router) {
    this.projectList = projects['default'];
  }

  ngOnInit(): void {}

  public goToProject(project) {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        projectId: project
      }
    };
    this.router.navigate(['/projects'], navigationExtras);
  }
 
  public goToServices() {
    this.router.navigate(['/services']);
  }

  public getDelay(index) {
    console.log(index);
    return {'animation-delay': index/5+'s'};
  }
}
