import { Component, HostListener } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';
 
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'dmwebsite';
 
  constructor(private router: Router, private viewportScroller: ViewportScroller) { }
 
  anchorLink(id: string) {
    if(id === 'projects') {
      this.goHome();
    }
    this.viewportScroller.scrollToAnchor(id);
  }
 
  public goHome() {
    this.router.navigate(['home']);
  }
  
  public goToImprint() {
    this.router.navigate(['imprint']);
  }
 
  public goToAboutMe() {
    this.router.navigate(['about-me']);
  }
 
  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll() {
    this.showScrollUp();
  }
 
  public showScrollUp() {
    if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
      return { display: 'block' };
    } else {
      return { display: 'none' };
    }
  }
 
  public scrollTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }
}
