<div class="container">
  <h3>{{currentProject.projectName}}</h3>
  <div class="row" style="margin-top:1.5em">
    <ng-container *ngFor="let element of currentProject.files; let i = index">
      <div *ngIf="isNotPDF(element)" class="cardContainer">
        <div class="card fade-in" [ngStyle]="getDelay(i)">
          <img src="../assets/imgs/projects/{{currentProject.id}}/{{element}}" alt="...">
        </div>
      </div>
      <div *ngIf="!isNotPDF(element)" class="cardContainer">
        <div class="card" style="display: flex; justify-content: center; align-items: center;">
          <a *ngIf="!isNotPDF(element)" [href]="getSrc(element)" download="{{element}}">
            <span class="material-icons" style="font-size:12em">
              picture_as_pdf
            </span>
          </a>
          <h5 style="text-align: center;">{{element}}</h5>
        </div>
      </div>
    </ng-container>
  </div>
</div>